<template>
  <div>
    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-row>
      <v-col md="6">
        <v-card>
          <v-card-text>
            <h4 class="text-md-h4 primary--text mb-5">
              {{ `${isEdit ? "Edit" : "Editing"} Consultant` }}
            </h4>
            <v-form ref="userForm" @submit.prevent="save()">
              <v-row>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <h2>Personal Details</h2>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col md="6">
                          <v-text-field
                            label="First Name"
                            v-model="userModel.firstName"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col md="6">
                          <v-text-field
                            label="Last Name"
                            v-model="userModel.lastName"
                            outlined
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col md="6">
                          <p class="display-2">
                            <strong>Assigned Title </strong>
                            {{ this.userModel.title || "Not specified yet" }}
                          </p>
                          <base-title-picker
                            color="primary"
                            :label="'Title'"
                            :chosen-title.sync="userModel.title"
                            :initial="userModel.title"
                            v-model="userModel.title"
                            outlined
                          />
                        </v-col>

                        <v-col md="6">
                          <p class="display-2">
                            <strong>Assigned relationship: </strong>
                            {{
                              this.userModel.relationship || "Not specified yet"
                            }}
                          </p>
                          <base-relationship-status-picker
                            :label="'Change Relationship Status'"
                            :initial="userModel.relationship"
                            :chosen-relationship-status.sync="
                              userModel.relationship
                            "
                            outlined
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col md="6">
                          <p class="display-2">
                            <strong>Assigned gender: </strong>
                            {{ this.userModel.gender || "Not specified yet" }}
                          </p>
                          <base-gender-picker
                            :label="'Change Gender'"
                            :initial="userModel.gender"
                            :chosen-gender.sync="userModel.gender"
                            outlined
                          />
                        </v-col>

                        <v-col md="6">
                          <p class="display-2">
                            <strong>Assigned race: </strong>
                            {{ this.userModel.race || "Not specified yet" }}
                          </p>
                          <base-race-picker
                            :label="'Change Race'"
                            :initial="userModel.race"
                            :chosen-race.sync="userModel.race"
                            outlined
                          />
                        </v-col>
                        <v-col md="12">
                          <v-text-field
                            label="Passport"
                            v-model="userModel.passport"
                            outlined
                            readonly
                            hint="This field cannot be edited by a consultant only an admin"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <h2>Contact Details</h2>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col md="6">
                          <v-text-field
                            label="Mobile"
                            v-model="userModel.telNumber"
                            prefix="(+27)"
                            outlined
                          ></v-text-field
                        ></v-col>
                        <v-col md="6">
                          <v-text-field
                            label="Alternative Number"
                            v-model="userModel.altNumber"
                            prefix="(+27)"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col md="6"
                          ><v-text-field
                            label="Email"
                            v-model="userModel.email"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col md="6">
                          <v-text-field
                            label="Business email"
                            v-model="userModel.businessEmail"
                            placeholder="someone@company.com"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col md="6">
                          <v-text-field
                            label="Fax Number"
                            v-model="userModel.faxNumber"
                            prefix="(+27)"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col md="6">
                          <v-text-field
                            label="Postal Code"
                            v-model="userModel.postalCode"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col md="12">
                          <v-text-field
                            label="Region"
                            v-model="userModel.region"
                            placeholder="Gauteng, JHB etc"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            v-model="userModel.bio"
                            outlined
                            counter
                            label="Notes on Consultant"
                            bio
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="mr-4" cols="12" sm="12" md="12">
                          <h2>Experience with consultant Rating</h2>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-rating
                            v-model="userModel.rating"
                            background-color="purple lighten-3"
                            half-increments
                            hover
                            length="5"
                            size="40"
                          ></v-rating>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <h2>Birth Details</h2></v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" md="12" sm="12">
                          <v-col>
                            <div>
                              <!-- <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="userModel.dob"
                                    label="Birthday date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    outlined
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="userModel.dob"
                                  outlined
                                  :max="
                                    new Date(
                                      Date.now() -
                                        new Date().getTimezoneOffset() * 60000
                                    )
                                      .toISOString()
                                      .substr(0, 10)
                                  "
                                  min="1900-01-01"
                                  @change="saveDob"
                                ></v-date-picker>
                              </v-menu> -->
                            </div>
                          </v-col>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col md="6">
                          <v-text-field
                            label="ID Number"
                            v-model="userModel.idNumber"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col md="6">
                          <v-text-field
                            label="Age"
                            v-model="userModel.age"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <h2>Address details</h2>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" md="12" sm="12">
                          <v-textarea
                            label="'Address'"
                            outlined
                            counter
                            v-model="userModel.fullAddress"
                          />
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col md="6">
                          <v-text-field
                            label="Postal Code"
                            v-model="userModel.postalCode"
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col md="6">
                          <v-text-field
                            label="Region"
                            v-model="userModel.region"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>

              <v-row>
                <v-col class="mr-6"> </v-col>
              </v-row>

              <v-row>
                <v-col class="mr-6 my-4">
                  <h2>Assigned Tags</h2>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="6">
                  <p class="display-2">
                    <strong>Assigned Branch: </strong>
                    {{ this.userModel.branchName || "Not assigned yet" }}
                  </p>
                  <base-branch-picker
                    :label="'Re-assign your branch'"
                    :initial="userModel.branch"
                    :chosen-branch.sync="userModel.branch"
                    outlined
                  />
                </v-col>

                <v-col md="6">
                  <p class="display-2">
                    <strong>Assigned Role: </strong>
                  </p>
                  <v-select
                    v-model="userModel.role"
                    v-if="roles && roles.roles"
                    item-text="name"
                    label="Role"
                    class="mt-12"
                    :items="roles.roles"
                    outlined
                  ></v-select>
                </v-col>
                <v-col md="12">
                  <p class="display-2">
                    <strong>Assigned industry: </strong>
                    {{ this.userModel.industry || "Not specified yet" }}
                  </p>
                  <base-industry-picker
                    :label="'Change your industry'"
                    :initial="userModel.industry"
                    :chosen-industry.sync="userModel.industry"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col md="12">
                  <v-text-field
                    label="Mailing preference"
                    v-model="userModel.mailingPref"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-spacer></v-spacer>
                <base-password-reset-modal />
                <v-spacer></v-spacer>
                <v-col class="text-right">
                  <v-btn type="submit" color="success" class="text-align-right">
                    <h1>Save</h1>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import { User } from "@/models/User";

export default {
  name: "EditUser",
  data() {
    return {
      tab: 0,
      tabs: ["View Timeline", "Edit Events"],
      isEdit: false,
      isLoading: false,
      userModel: new User(),
      menu: false,
      date: null,
      search: "",
      dialog3: false,
      totalRecords: 0,
      crumbs: [
        {
          text: "Consultants",
          disabled: false,
          href: "/admin/consultants",
        },
        {
          text: "Consultant Detail",
          disabled: false,
          href: `/admin/consultant/${this.$route.params.id}`,
        },
        {
          text: `${this.isEdit ? "Edit" : "New"} consultant`,
          disabled: true,
        },
      ],

      me: "",
      headers: [
        {
          text: "Explanation",
          align: "start",
          sortable: false,
          value: "explanation",
        },

        { text: "Created Date", value: "createdAt", align: "left" },
        { text: "Actions", align: "left", sortable: false, value: "actions" },
      ],
    };
  },
  created() {
    this.isEdit = this.$route.params.id != 0;
    this.isLoading = this.isEdit ? true : false;
  },
  computed: {},

  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,

    user: {
      query: gql`
        query User($id: ID!) {
          user(id: $id) {
            id
            firstName
            lastName
            title
            relationship
            gender
            race
            telNumber
            altNumber
            email
            businessEmail
            faxNumber
            bio
            rating
            age
            # dob
            idNumber
            passport
            fullAddress
            industry
            role
            referral
            branch
            branchName
            consultant
            # consultantName
            postalCode
            region
            passport
            mailingPref
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.isEdit;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      result(response, key) {
        this.isLoading = false;
        this.userModel.id = this.user.id;
        this.userModel.firstName = this.user.firstName;
        this.userModel.lastName = this.user.lastName;
        this.userModel.title = this.user.title;
        this.userModel.relationship = this.user.relationship;
        this.userModel.gender = this.user.gender;
        this.userModel.race = this.user.race;
        this.userModel.telNumber = this.user.telNumber;
        this.userModel.altNumber = this.user.altNumber;
        this.userModel.email = this.user.email;
        this.userModel.businessEmail = this.user.businessEmail;
        this.userModel.faxNumber = this.user.faxNumber;
        this.userModel.rating = this.user.rating;
        this.userModel.bio = this.user.bio;
        // this.userModel.dob = this.user.dob;
        this.userModel.age = this.user.age;
        this.userModel.idNumber = this.user.idNumber;
        this.userModel.passport = this.user.passport;
        this.userModel.fullAddress = this.user.fullAddress;
        this.userModel.industry = this.user.industry;
        this.userModel.role = this.user.role;
        this.userModel.referral = this.user.referral;
        this.userModel.consultant = this.user.consultant;
        this.userModel.branch = this.user.branch;
        this.userModel.branchName = this.user.branchName;
        this.userModel.postalCode = this.user.postalCode;
        this.userModel.region = this.user.region;
        this.userModel.mailingPref = this.user.mailingPref;
      },
    },
    roles: {
      query: gql`
        query {
          __type(name: "Role") {
            roles: enumValues {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
    eventStatuses: {
      query: gql`
        query {
          __type(name: "EventStatus") {
            eventStatuses: enumValues {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
  },
  methods: {
    // saveDob(date) {
    //   this.$refs.menu.save(date);
    // },
    async save() {
      this.isLoading = true;
      const self = this;
      if (!self.$refs.userForm.validate()) {
        self.$swal("Error", "Validation failed", "error");

        self.isLoading = false;
        return;
      }

      if (self.isEdit) {
        await this.updateUser()
          .then(() => {
            this.$swal("Success!", "Consultant was updated", "success");
            window.location.href = `/admin/consultant/${self.$route.params.id}/edit`;
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              "something went wrong when trying to update the Consultant, check data and try again later!",
              "error"
            );
          });
      } else {
        await this.createUser()
          .then(() => {
            this.$swal("Success!", "Consultant was created", "success");
            window.location.href = `/admin/consultant/${self.userModel.id}/edit`;
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              `Something went wrong when creating the Consultant, check data and try again later!`,
              "error"
            );
          });
      }
      this.isLoading = false;
    },
    async updateUser() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation updateUser($user: UserUpdateInput!) {
              updateUser(user: $user) {
                id
                updated
              }
            }
          `,
          variables: {
            user: {
              id: self.userModel.id,
              firstName: self.userModel.firstName,
              lastName: self.userModel.lastName,
              title: self.userModel.title,
              relationship: self.userModel.relationship,
              gender: self.userModel.gender,
              race: self.userModel.race,
              telNumber: self.userModel.telNumber,
              altNumber: self.userModel.altNumber,
              email: self.userModel.email,
              businessEmail: self.userModel.businessEmail,
              faxNumber: self.userModel.faxNumber,
              region: self.userModel.region,
              postalCode: self.userModel.postalCode,
              bio: self.userModel.bio,
              rating: self.userModel.rating,
              // dob: self.userModel.dob,
              idNumber: self.userModel.idNumber,
              passport: self.userModel.passport,
              fullAddress: self.userModel.fullAddress,
              industry: self.userModel.industry,
              role: self.userModel.role,
              referral: self.userModel.referral,
              branch: self.userModel.branch,
              branchName: self.userModel.branchName,
              consultant: self.userModel.consultant,
            },
          },
        });
        if (!response) {
          throw "Something went wrong when creating the Consultant!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
      } catch (e) {
        throw e.message;
      }
    },
    async createUser() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createUser($user: UserCreateInput!) {
              createUser(user: $user) {
                id
              }
            }
          `,
          variables: {
            user: {
              firstName: self.userModel.firstName,
              lastName: self.userModel.lastName,
              title: self.userModel.title,
              relationship: self.userModel.relationship,
              gender: self.userModel.gender,
              race: self.userModel.race,
              telNumber: self.userModel.telNumber,
              altNumber: self.userModel.altNumber,
              email: self.userModel.email,
              businessEmail: self.userModel.businessEmail,
              faxNumber: self.userModel.faxNumber,
              bio: self.userModel.bio,
              rating: self.userModel.rating,
              // dob: self.userModel.dob,
              idNumber: self.userModel.idNumber,
              passport: self.userModel.passport,
              fullAddress: self.userModel.fullAddress,
              industry: self.userModel.industry,
              role: "employee",
              referral: self.userModel.referral,
              branch: self.userModel.branch,
              branchName: self.userModel.branchName,
              consultant: self.userModel.consultant,
              postalCode: self.userModel.postalCode,
              region: self.userModel.region,
              mailingPref: self.userModel.mailingPref,
            },
          },
        });

        if (!response) {
          throw "Something went wrong when creating the User!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
        if (response && response.data.createUser) {
          self.userModel.id = response?.data?.createUser?.id;
        }
      } catch (e) {
        throw e.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
